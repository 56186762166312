<!--footer section -->
<footer [class]="class">
	<section class="section-b-space dark-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-3 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4 style="color: #fff">about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img  src="assets/images/logo/logo-inline.png" class="img-fluid" alt="logo" style="width:250px">
						</div>
						<p>
							Mcalculator is a free internet calculator. Free loan, mortgage, cash value, math, algebra,
							trigonometry, fractions, physics, statistical information, time & date, and conversion
							calculators are provided here.
						</p>
						<div class="footer-social">
							<ul>
								<li>
									<a  href="javascript:void(0)"><i class="fa fa-facebook" style="color: white" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-linkedin" style="color: white" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-instagram" style="color: white" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-pinterest" style="color: white" aria-hidden="true"></i></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">

						</div>
						<div class="footer-contant">
							<ul>
								<li><a [routerLink]="'/financial/interest-calculator'">INTEREST CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/compound-interest-calculator'">COMPOUND INTEREST CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/emi-calculator'">EMI CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/investment-calculator'">INVESTMENT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/payment-calculator'">PAYMENT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/amortization-calculator'">AMORTIZATION CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/mortgage-calculator'">MORTGAGE CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/fd-calculator'">FD CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/roi-calculator'">ROI CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/payback-period-calculator'">PAYBACK PERIOD CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/inflation-calculator'">INFLATION CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/auto-lease-calculator'">AUTO LEASE CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/rental-property-calculator'">RENTAL PROPERTY CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/retirement-calculator'">RETIREMENT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/estate-tax-calculator'">ESTATE TAX CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/personal-loan-calculator'">PERSONAL LOAN CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/refinance-calculator'">REFINANCE CALCULATOR</a></li>
							</ul>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="sub-title">
						<div class="footer-title">

						</div>
						<div class="footer-contant">
							<ul>
								<li><a [routerLink]="'/financial/down-payment-calculator'">DOWN PAYMENT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/house-affordability-calculator'">HOUSE AFFORDABILITY CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/rent-calculator'">RENT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/dti-calculator'">DTI CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/auto-loan-calculator'">AUTO LOAN CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/saving-calculator'">SAVING GOAL CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/cashback-calculator'">CASH BACK CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/cd-calculator'">CD CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/fha-calculator'">FHA CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/annuity-calculator'">ANNUITY CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/present-value-calculator'">PRESENT VALUE CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/future-value-calculator'">FUTURE VALUE CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/lease-calculator'">LEASE CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/sales-tax-calculator'">SALES TAX CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/margin-calculator'">MARGIN CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/discount-calculator'">DISCOUNT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/vat-calculator'">VAT CALCULATOR</a></li>
								<li><a [routerLink]="'/financial/budget-calculator'">BUDGET CALCULATOR</a></li>

							</ul>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="sub-title">
						<div class="footer-title">

						</div>
						<div class="footer-contant">
							<ul>
								<li><a [routerLink]="'/fitness/bmi-calculator'">BMI CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/bmr-calculator'">BMR CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/calorie-calculator'">CALORIE CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/ideal-weight-calculator'">IDEAL WEIGHT CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/Lean-body-mass-calculator'">LBM CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/body-fat-calculator'">BODY FAT CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/healthy-weight-calculator'">HEALTHY WEIGHT CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/weight-watcher-points-calculator'">WEIGHT WATCHER CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/protein-calculator'">PROTEIN CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/carbohydrate-calculator'">CARBOHYDRATE CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/body-type-calculator'"> BODY TYPE CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/body-surface-area-calculator'">BODY SURFACE AREA CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/fat-intake-calculator'">FAT INTAKE CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/tdee-calculator'">TDEE CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/macro-calculator'">MACRO CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/army-body-fat-calculator'">ARMY BODY FAT CALCULATOR</a></li>
								<li><a [routerLink]="'/fitness/pace-calculator'">PACE CALCULATOR</a></li>

							</ul>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="sub-title">
						<div class="footer-title">

						</div>
						<div class="footer-contant">
							<ul>
								<li><a [routerLink]="'/misc/age-calculator'">AGE CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/wind-chill-calculator'">WIND CHILL CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/date-calculator'">DATE CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/time-calculator'">TIME CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/hours-calculator'">HOURS CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/day-calculator'">DAY CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/height-calculator'">HEIGHT CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/sleep-calculator'">SLEEP CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/gdp-calculator'">GDP CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/fuel-cost-calculator'">FUEL COST CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/gas-mileage-calculator'">GAS MILEAGE CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/engine-horsepower-calculator'"> ENGINE HORSEPOWER CALCULATOR</a></li>
								<li><a [routerLink]="'/misc/weight-calculator'"> WEIGHT CALCULATOR</a></li>
							</ul>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="sub-title">
						<div class="footer-title">

						</div>
						<div class="footer-contant">
							<ul>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards">GROUP GREETING CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/happy-birthday-cards">FREE BIRTHDAY CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/farewell">FAREWELL CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/farewell">LEAVING CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/sympathy">SYMPATHY CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/retirement">RETIREMENT CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/office-anniversary">WORK ANNIVERSORY CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/wedding-engagement">WEDDING CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/farewell">GOOD BYE CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/bridal-shower">BRIDEL SHOWER CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/congratulation">CONGRATULATIONS CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/appreciation">APPRECIATION CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/thank-you">THANK YOU CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/get-well-soon">GET WELL SOON CARDS</a></li>
								<li><a target="_blank" href="https://sendwishonline.com/en/group-cards/funny-get-well-soon-cards">FUNNY GET WELL SOON CARDS</a></li>

							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end ">
						<p class=" m-auto">Copyright <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} MCALCULATORS. All rights reserved.</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12 ">
					<div class="footer-end justify-content-center float-right footer-contant">
						<ul class="m-4 ">
							<li ><a [routerLink]="'/pages/contact-us'">Contact Us</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->
