import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BarRatingModule } from "ngx-bar-rating";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Header and Footer Components
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';

// Components
import { MenuComponent } from './components/menu/menu.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

// Modals Components
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Pipes
import { DiscountPipe } from './pipes/discount.pipe';

@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    MenuComponent,
    BreadcrumbComponent,
    NewsletterComponent,
    TapToTopComponent,
    DiscountPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BarRatingModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BarRatingModule,
    NgxSkeletonLoaderModule,
    HeaderOneComponent,
    FooterOneComponent,
    BreadcrumbComponent,
    NewsletterComponent,
    TapToTopComponent,
    DiscountPipe
  ]
})
export class SharedModule { }
