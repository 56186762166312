import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(@Inject(DOCUMENT) private dom,private http : HttpClient) { }
  createCanonicalURL() {
    let _url = this.dom.URL.replace("http://","https://");
    let _final_url =  _url.split("?")[0];
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',_final_url)
  }

  getCanonicalURL(){
    return this.dom.URL.replace("http://","https://");
  }


  checkEmail(payloads){
    return this.http.post("https://podsliving.codeonpage.com/api/v1/checkemail",payloads);
  }

}
