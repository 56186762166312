import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {MetaService} from '../shared/services/meta.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(private metaTagService: Meta, private titleService: Title, private metaService: MetaService) {
    }

    ngOnInit(): void {
        this.metaService.createCanonicalURL();

        this.titleService.setTitle('Free Online Calculator | Mcalculators.com');

        this.metaTagService.addTags([
            {
                name: 'description',
                content: 'Online calculator for quick calculations, along with a huge collection of calculators on math, finance, fitness, and more.'
            }, {
                name: 'og:url',
                content: this.metaService.getCanonicalURL(),
            }
        ]);
    }

}
