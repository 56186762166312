import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import {FinancialComponent} from "./financial/financial.component";
import {HomeComponent} from './home/home.component';
import {FitnessComponent} from './fitness/fitness.component';
import {MiscComponent} from './misc/misc.component';

const routes: Routes = [
  {
    path: '',
    component : HomeComponent
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  {
    path: 'financial',
    component : FinancialComponent,
    loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule)
  },
  {
    path: 'fitness',
    component : FitnessComponent,
    loadChildren: () => import('./fitness/fitness.module').then(m => m.FitnessModule)
  },
  { path: 'misc',
    component : MiscComponent,
    loadChildren: () => import('./misc/misc.module').then(m => m.MiscModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
