<app-header-one [sticky]="true"></app-header-one>

    <section class="header all-section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <h1 class="home-heading">Free Online <br/> Calculator</h1>
                    <p class="home-subhead">The Mcalculator project is a collection of high-quality  free online calculator that have been meticulously designed to operate on a variety of devices,
                        including PCs, smartphones, and tablets. This calculator is still in progress, and the goal is to have a calculator available for any of our community's requirements,
                        whether they are personal or business-related.</p>
                    <p class="home-subhead"> Although our free online calculator may be regarded as technical,
                        we see this as our calling and are passionate about offering it to our clients,
                        who will benefit from our easy-to-use calculators.  </p>
                    <p class="home-subhead">  </p>
                    <p class="home-subhead">  </p>
                </div>
                <div class="col-lg-6 col-12">
                    <img src="./assets/images/home/m-calculator.jpg" class="img-fluid" alt="online free calculator"/>
                </div>
            </div>
        </div>
    </section>

<!---------------------------------------------------- Financial calculator--------------------------->
<section class="all-section-padding ">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center calculator-heading">Financial Calculators</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/amortization-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/amortization.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Amortization Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/annuity-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/annuities.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Annuity Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/auto-lease-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/leasing.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Auto Lease Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/auto-loan-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/autoLoan.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center"> Auto-Loan Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/budget-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/budget.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Budget Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/cashback-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/cash-back.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Cash Back Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/cd-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/certificate.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">CD Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/compound-interest-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/calculator%20(2).png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Compound Interest</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/dti-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/debt.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center"> Debt-To-Income Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/down-payment-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/asset.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Down Payment Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/discount-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/discount.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Discount Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a routerLink="/financial/emi-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/calculator.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">EMI Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/estate-tax-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/taxes.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Estate Tax Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/fd-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/piggy-bank.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">FD Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/fha-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/FHA.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">FHA Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/future-value-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/future-value-.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Future Value Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/house-affordability-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/affordability.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">House Affordability Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a routerLink="/financial/investment-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/investment.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Investment Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/interest-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/SIP.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Interest Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/inflation-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/inflation.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Inflation Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/lease-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/lease.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Lease Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/mortgage-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/mortgage-house.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Mortgage Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/margin-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/margin.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Margin Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/payment-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/payment.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Payment Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/present-value-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/present-value.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Present Value Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/payback-period-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/payback.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Payback Period Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/personal-loan-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/personal-loan.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Personal Loan Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/roi-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/ROI.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">ROI Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/rent-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/rent.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Rent Affordability Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/retirement-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/retirement.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Retirement Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/rental-property-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/house-rental.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Rental Property Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/refinance-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/refinance.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Refinance Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/sales-tax-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/sales-tax.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Sales Tax Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card" >
                    <a routerLink="/financial/saving-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/goal.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center"> Saving Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/financial/vat-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/finance/vat.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">VAT Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!---------------------Health-------------------------------------------------->
<section class="all-section-padding header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center calculator-heading">Fitness & Health Calculators</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a routerLink="/fitness/army-body-fat-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fitness-img/army-body-fat.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Army Body Fat Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/bmi-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/body-mass-index.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">BMI Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/bmr-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/metabolism.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">BMR Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/body-surface-area-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="../../../assets/images/fitness-img/measuring.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Body Surface Area Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/body-type-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fitness-img/body-type.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Body Type Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/body-fat-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/body-fat.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Body Fat Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/calorie-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/calories.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">calorie Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/carbohydrate-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/carbohydrates.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Carbohydrate Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/fat-intake-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fitness-img/no-fat.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Fat Intake Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/healthy-weight-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/body-weight.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Healthy Weight Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/ideal-weight-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/weight.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Ideal Weight Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/Lean-body-mass-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fit.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Lean Body Mass Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a routerLink="/fitness/macro-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fitness-img/macro.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Macro Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a routerLink="/fitness/pace-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fitness-img/Pace.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Pace Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/protein-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/protein.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Protein Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/tdee-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/fitness-img/TDEE.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">TDEE Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/fitness/weight-watcher-points-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/weight-watcher.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Weight Watcher Points Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!---------------------Other-------------------------------------------------->
<section class="all-section-padding ">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="text-center calculator-heading">Other Calculator</h2>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/age-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/age.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Age Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/date-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/date.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Date Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/day-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/day.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Day Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/engine-horsepower-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/engine.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Engine Horsepower Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/fuel-cost-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/fuel.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Fuel Cost Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/gas-mileage-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/gas.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Gas Mileage Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/gdp-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/gdp.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">GDP Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/height-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/height.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Height Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/hours-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/hours.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Hours Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/sleep-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/sleeping.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Sleep Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/time-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/time.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Time Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/weight-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/scale.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Weight Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
                <div class="card">
                    <a  routerLink="/misc/wind-chill-calculator">
                        <div class="card-body d-inline-flex p-3">
                            <img src="assets/images/misc/weathercock.png">
                            <h4 class="card-title mt-2 ml-4 align-self-center">Wind Chill Calculator</h4>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--footer-->
<section class="all-section-padding ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-12">

                <p class="home-subhead"> Although our free online calculator may be regarded as technical,
                    we see this as our calling and are passionate about offering it to our clients,
                    who will benefit from our easy-to-use calculators. For you to completely understand how
                    our calculator arrived at its result, we offer a comprehensive step-by-step explanation
                    for all of the calculations or equations that you have put into one of our calculators
                    whenever possible. This not only provides you with the answer you were looking for,
                    but it also provides you with an understanding of how all of our calculators operate. </p>
                <p class="home-subhead"> We now offer over 25 calculators to assist you with your math needs,
                    whether it's in the financial sector or with your schoolwork, or in areas such as weight loss, BMI
                    , and a variety of other areas. We are always striving to offer our community a greater range
                    of free online calculator by expanding our development efforts </p>
                <p class="home-subhead"> We offers you a free online calculator because we think that all of the knowledge
                    available on the internet should be free of charge and accessible to everyone without difficulty!
                    We ask that you give us feedback and tell us any suggestions you have for our calculators so that
                    we can make sure that any problems are fixed and that we can introduce new features and improvements. </p>

                <p class="home-subhead">Providing quick, comprehensive, convenient, and free online calculator in a wide range of topics is the primary emphasis of Mcalculator's operations.
                    Our current inventory includes over 25 calculators to assist you in "doing the math" fast in areas such as money, fitness, health, math, and others, and we are always
                    adding new ones to our collection. To do this, we want to become the one-stop, go-to site for individuals who need to perform fast computations. In addition,
                    we think that the internet should be a free source of knowledge for everyone. So all of our products and services are free,
                    with no need to sign up or create an account with us. </p>
                <p class="home-subhead">We programmed and built each calculator separately, and we put each one through a rigorous, thorough testing process before releasing it.
                    Although we appreciate your feedback, please notify us as soon as you discover any errors, no matter how little. Your feedback is very important to us.
                    While the majority of the calculators on Mcalculator whether it is Mortgage calculator, Amortization Calculator, Interest Calculator etc.
                    are intended to be generally suitable for use across the world. Our free online calculator will give you exact accurate result of your Maths. </p>

            </div>
        </div>
    </div>
</section>
<app-footer-one></app-footer-one>

